<template>
  <TopNav></TopNav>
</template>

<script>
import TopNav from './components/TopNav.vue'

export default {
  name: 'App',
  components: {
    TopNav
  }
}
</script>

<style>
footer section a {
  margin: 20px;
}
</style>
